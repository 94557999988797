import React from "react";
import { Route, Routes } from "react-router-dom";
import Layout from "../components/layout/Layout";
import NavbarDetail from "../components/layout/navbar/navbarDetail/NavbarDetail";
import Home from "../components/screen/Home";
import AllNewsList from "../pages/allNews/allnewslist/AllNewsList";
import MarkaziyAprt from "../pages/markaziyapparat/MarkaziyAprt";
import BudgetDetail from "../pages/openData/budgetData/budgetDetail/BudgetDetail";
import Budgetmalumotlari from "../pages/openData/budgetData/Budgetmalumotlari";
import Normativ from "../pages/openData/normativdocument/Normativ";
import PhotoGalery from "../pages/photoGalery/PhotoGalery";
import PostDetail from "../pages/postDetail/PostDetail";
import Rahbariyat from "../pages/rahbariyat/Rahbariyat";
import NormativDetail from "../pages/openData/normativdocument/normativDetail/NormativDetail";
import Ochiqmalumotlar from "../pages/openData/openDate/Ochiqmalumotlar";
import NavbarExtraDetail from "../components/layout/navbar/navbarExtraDetail/NavbarExtraDetail";
import SaytXaritasi from "../pages/saytXaritasi/SaytXaritasi";
import SendMsgRahbariyat from "../pages/rahbariyat/sendmsgrahbariyat/SendMsgRahbariyat";
import Search from "../components/search/Search";
import SendMessageAnti from './../pages/sendMessageAntiKarupsiya/SendMessageAnti';

const MainRoutes = () => {
  return (
    <React.Fragment>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="pdetail/:postid" element={<PostDetail />} />
          <Route path="/photogalery" element={<PhotoGalery />} />
          <Route path="/all-news" element={<AllNewsList />} />
          <Route path="/ndetail/:navid" element={<NavbarDetail />} />
          <Route path="/ndetail/rahbariyat" element={<Rahbariyat />} />
          <Route path="/ndetail/markaziy-apparat" element={<MarkaziyAprt />} />
          <Route path="/open-data/budget/:bgid" element={<BudgetDetail />} />
          <Route
            path="/ndetail/budjet-malumotlari"
            element={<Budgetmalumotlari />}
          />
          <Route path="/ndetail/normativ-hujjatlar" element={<Normativ />} />
          <Route path="/post" element={<Search />} />
          <Route
            path="/ndetail/normativ-hujjatlar/detail/:nmid"
            element={<NormativDetail />}
          />
          <Route path="/ndetail/pf-6247" element={<Ochiqmalumotlar />} />
          <Route path="/:nexid" element={<NavbarExtraDetail />} />
          <Route path="/saytxaritasi" element={<SaytXaritasi />} />
          <Route path="/murojaat/:rhid" element={<SendMsgRahbariyat />} />
          <Route path="ak/murojaat-yuborish" element={<SendMessageAnti />} />
        </Route>
      </Routes>
    </React.Fragment>
  );
};

export default MainRoutes;
